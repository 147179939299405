import { useCallback, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
//
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import FilesSelector from "../FilesSelector";
import getValidationErrors from "../../../../../utils/getValidationErrors";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";

const validationSchema = Yup.object().shape({
  category: Yup.string().required("The Category field is required."),
  title: Yup.string().required("The Title field is required."),
  content: Yup.string().required("The Content field is required."),
});

function CreateTicketForm() {
  let [isLoading, setIsLoading] = useState(false);
  let [ticket, setTicket] = useState({});
  let [registeredSupportFiles, setRegisteredSupportFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  let [errors, setErrors] = useState([]);
  // let [fieldsValues, setFieldsValues] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { ticketCategories } = location.state;
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();

  const handleSelectedFiles = useCallback((files) => {
    setSelectedFiles(files);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setErrors([]);
      setIsLoading(true);
      await validationSchema.validate(ticket, {
        abortEarly: false,
      });
      let formData = new FormData();
      Object.keys(ticket).forEach((key) => {
        formData.append(key, ticket[key]);
      });
      selectedFiles.forEach((file) => {
        formData.append("image", file);
      });
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ": " + pair[1]);
      // }
      // console.log(formData.entries());
      const response = await api.post(`/v1/dashboard/tickets`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setIsLoading(false);
      if (response.data && response.data.code !== 201) {
        if (response.data.status === "Unauthorized") {
          setUnAuthorizedMsg(
            `${response.data.message} - You have to login again.`
          );
        }
      } else {
        // console.log(response.data.ticket_data);
        const ticket = response.data.ticket_data;
        history.push(`/dashboard/support-tickets/${ticket.id}`);
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        let validationErrors = [];
        Object.keys(errors).forEach((key) => {
          validationErrors.push(errors[key]);
        });
        setErrors(validationErrors);
        setIsLoading(false);
        return;
      }
    }
  }, [selectedFiles, ticket]);

  const handleFieldChange = useCallback(
    (event) => {
      const newTicket = { ...ticket };
      if (event.target.name === "category") {
        newTicket[event.target.name] = parseInt(event.target.value);
      } else {
        newTicket[event.target.name] = event.target.value;
      }
      setTicket(newTicket);
    },
    [ticket]
  );

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {errors.length > 0 && (
        <div className="message-errors-container alert alert-danger">
          {errors.map((err, index) => (
            <span key={index}>{err}</span>
          ))}
        </div>
      )}
      {!isLoading && (
        // <form onSubmit={handleSubmit}>
        <form>
          <label for="category">Category:</label>
          <select
            className="form-group form-control"
            name="category"
            value={ticket.category}
            onChange={handleFieldChange}
            defaultValue="default"
          >
            <option disabled value="default">
              {" "}
              -- select an option --{" "}
            </option>
            {ticketCategories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <label for="title">Title:</label>
          <input
            type="text"
            name="title"
            onChange={handleFieldChange}
            className="form-group form-control"
            value={ticket.title}
          />
          <label for="content">Content:</label>
          <textarea
            name="content"
            className="form-group form-control"
            style={{ minHeight: 200, resize: "none" }}
            onChange={handleFieldChange}
            value={ticket.content}
          />
          <FilesSelector
            currentFiles={selectedFiles}
            handleSelectedFiles={handleSelectedFiles}
            registeredSupportFiles={registeredSupportFiles}
          />
          <div className="action-buttons">
            <button
              type="button"
              className="btn btn-default"
              onClick={handleSubmit}
            >
              Create
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CreateTicketForm;
