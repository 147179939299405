import React, { useEffect, useState, useCallback } from "react";

const COMMOM_STATUS = {
  ALL: {
    value: -1,
    text: "All",
  },
};

function FilterForm({ onFilterChange = () => null, statusValues = {} }) {
  // let [searchText, setSearchText] = useState("");
  let [selectedStatus, setSelectedStatus] = useState(COMMOM_STATUS.ALL.value);
  let [statusObject, setStatusObject] = useState({ ...COMMOM_STATUS });
  let [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    setStatusObject({ ...statusObject, ...statusValues });
  }, []);

  const handleSearchTextChange = useCallback(
    (event) => {
      let queryObj = { search: null };
      if (event.target.value.trim()) {
        queryObj.search = event.target.value;
      }
      // setSearchText(event.target.value);
      onFilterChange(queryObj);
    },
    [onFilterChange]
  );

  const handleStatusChange = useCallback(
    (event) => {
      let queryObj = { status: null };
      if (parseInt(event.target.value) !== COMMOM_STATUS.ALL.value) {
        queryObj.status = event.target.value;
      }
      setSelectedStatus(event.target.value);
      onFilterChange(queryObj);
    },
    [onFilterChange]
  );

  const handleStartDateChange = useCallback(
    (event) => {
      let queryObj = { start_date: null };
      if (event.target.value.trim()) {
        queryObj.start_date = event.target.value;
      }
      setDateRange({ ...dateRange, startDate: event.target.value });
      onFilterChange(queryObj);
    },
    [onFilterChange, dateRange]
  );

  const handleEndDateChange = useCallback(
    (event) => {
      let queryObj = { end_date: null };
      if (event.target.value.trim()) {
        queryObj.end_date = event.target.value;
      }
      setDateRange({ ...dateRange, endDate: event.target.value });
      onFilterChange(queryObj);
    },
    [onFilterChange, dateRange]
  );

  return (
    <div className="transaction-filter-form">
      <div class="form-group">
        <label for="usr">Search:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          id="search-text"
          // value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>
      <div className="form-group">
        <label for="status">Status:</label>
        <select
          className="form-control"
          id="status"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          {Object.keys(statusObject).map((key) => (
            <option value={statusObject[key].value}>
              {statusObject[key].text}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label for="start_date">From:</label>
        <input
          type="date"
          id="start_date"
          name="start_date"
          className="form-control"
          // value={dateRange.startDate}
          onChange={handleStartDateChange}
        />
      </div>
      <div className="form-group">
        <label for="start_date">To:</label>
        <input
          type="date"
          id="end_date"
          name="end_date"
          className="form-control"
          // value={dateRange.endDate}
          onChange={handleEndDateChange}
        />
      </div>
    </div>
  );
}

export default FilterForm;
