import { useCallback, useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
//
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";
import FilterForm from "../../../components/FilterForm";

const defaultActivePage = 1;
const itemsCountPerPage = 10;

const TICKET_STATUS = {
  ANALYZING: {
    value: 0,
    text: "Analyzing",
  },
  RESOLVED: {
    value: 1,
    text: "Resolved",
  },
  ATTENDING: {
    value: 2,
    text: "Attending",
  },
  WAITING_FOR_CUSTOMER: {
    value: 3,
    text: "Waiting for customer response",
  },
};

function TicketsPainel() {
  let [isLoading, setIsLoading] = useState(false);
  let [tickets, setTickets] = useState([]);
  let [ticketCategories, setTicketCategories] = useState([]);
  let [activePage, setActivePage] = useState(defaultActivePage);
  let [totalItemsCount, setTotalItemsCount] = useState(0);
  let [filtersQueryObj, setFiltersQueryObj] = useState({});
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();

  useEffect(() => {
    const getTicketsData = async () => {
      try {
        setIsLoading(true);
        const offSet = (activePage - 1) * itemsCountPerPage;
        let filtersQueryStr = "";
        Object.keys(filtersQueryObj).forEach((key) => {
          if (filtersQueryObj[key]) {
            filtersQueryStr += `&${key}=${filtersQueryObj[key]}`;
          }
        });
        const url = `/v1/dashboard/tickets?offset=${offSet}&limit=${itemsCountPerPage}${filtersQueryStr}`;
        console.log(url);
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setIsLoading(false);
        if (response.data && response.data.code !== 200) {
          if (response.data.status === "Unauthorized") {
            setUnAuthorizedMsg(
              `${response.data.message} - You have to login again.`
            );
          }
        } else {
          const renewed_token = response.data.access_token;
          updateToken(renewed_token);
          setTickets(response.data.data.tickets);
          setTotalItemsCount(response.data.data.total);
          setTicketCategories(response.data.data.categories);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getTicketsData();
  }, [activePage, filtersQueryObj]);

  const handlePageChange = useCallback((pageNumber) => {
    setActivePage(pageNumber);
  }, []);

  const handleFilterChange = useCallback(
    (queryObj) => {
      setFiltersQueryObj({ ...filtersQueryObj, ...queryObj });
      setActivePage(defaultActivePage);
    },
    [filtersQueryObj]
  );

  return (
    <div>
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>Support Tickets</h4>
        </div>
        <div className="panel-body">
          <div className="table-responsive">
            <div className="top-action-buttons">
              <Link
                to={{
                  pathname: "/dashboard/support-tickets/create",
                  state: { ticketCategories },
                }}
                className="btn btn-primary"
              >
                New Ticket +
              </Link>
            </div>
            <FilterForm
              onFilterChange={handleFilterChange}
              statusValues={TICKET_STATUS}
            />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <>
                <header className="header-table">
                  <span>Total: {totalItemsCount}</span>
                </header>
                {tickets.length > 0 && (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>category</th>
                        <th>title</th>
                        {/* <th>content</th> */}
                        <th>created on</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map((t, index) => {
                        let statusClass = "orange-value";
                        if (t.status?.includes("Resolved"))
                          statusClass = "green-value";
                        else if (
                          t.status?.includes("Attending") ||
                          t.status?.includes("Waiting for")
                        )
                          statusClass = "blue-value";
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`/dashboard/support-tickets/${t.id}`}>
                                {t.id}
                              </Link>
                            </td>
                            <td>{t.category}</td>
                            <td>{t.title}</td>
                            {/* <td>{t.content}</td> */}
                            <td>{t.created_on}</td>
                            <td className={statusClass}>{t.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
        <div className="pagination-container">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default TicketsPainel;
