import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function FilesSelector({
  currentFiles,
  handleSelectedFiles,
  registeredSupportFiles,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      let fileAlreadySelected = false;
      acceptedFiles.forEach((file) => {
        currentFiles.find((f) => {
          if (file.path === f.path) {
            fileAlreadySelected = true;
            return;
          }
        });
        if (fileAlreadySelected) {
          return;
        }
      });
      if (fileAlreadySelected) {
        alert("File already selected!");
      } else {
        const newArray = currentFiles.concat(acceptedFiles);
        handleSelectedFiles(newArray);
      }
    },
    [currentFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/jpeg", "image/pjpeg", "image/png", "image/gif"],
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleExcludeFile = useCallback(
    (filePath) => {
      const newArray = currentFiles.filter((file) => file.path !== filePath);
      handleSelectedFiles(newArray);
    },
    [currentFiles]
  );

  const files = currentFiles.map((file, index) => (
    <tr key={index}>
      <td className="image">
        <a
          href={URL.createObjectURL(file)}
          target="_blank"
        >
          <img src={URL.createObjectURL(file)} />
        </a>
      </td>
      <td>{file.path}</td>
      <td className="action-button">
        <a className="btn" onClick={() => handleExcludeFile(file.path)}>
          X
        </a>
      </td>
    </tr>
  ));

  const registeredFiles = registeredSupportFiles.map((sFile, index) => (
    <tr key={index}>
      <td className="image">
        <a
          href={`${process.env.REACT_APP_TICKETS_BUCKET_BASE_URL}/${sFile.file}`}
          target="_blank"
        >
          <img
            src={`${process.env.REACT_APP_TICKETS_BUCKET_BASE_URL}/${sFile.file}`}
          />
        </a>
      </td>
      <td>{sFile.file}</td>
      <td></td>
    </tr>
  ));

  return (
    <section>
      <aside id="selected-files-container">
        {(registeredFiles.length > 0 || files.length > 0) && (
          <>
            <h4>Images:</h4>
            <table className="table table-hover">
              <tbody>
                {registeredFiles}
                {files}
              </tbody>
            </table>
          </>
        )}
      </aside>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
    </section>
  );
}

export default FilesSelector;
