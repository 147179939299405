import { useCallback, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
//
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import FilesSelector from "../FilesSelector";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";

function ViewEditTicketForm() {
  const { id } = useParams();
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();
  let [isLoading, setIsLoading] = useState(false);
  let [ticket, setTicket] = useState({});
  let [registeredSupportFiles, setRegisteredSupportFiles] = useState([]);
  let [registeredSupportMessages, setRegisteredSupportMessages] = useState([]);
  let [selectedFiles, setSelectedFiles] = useState([]);
  let [errors, setErrors] = useState([]);
  let [showSupportTextarea, setShowSupportTextarea] = useState(false);
  let [supportMessage, setSupportMessage] = useState("");

  useEffect(() => {
    const getTicketData = async () => {
      try {
        setIsLoading(true);
        const url = `/v1/dashboard/tickets?id=${id}`;
        // console.log(url);
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response);
        setIsLoading(false);
        if (response.data && response.data.code !== 200) {
          if (response.data.status === "Unauthorized") {
            setUnAuthorizedMsg(
              `${response.data.message} - You have to login again.`
            );
          }
        } else {
          const renewed_token = response.data.access_token;
          updateToken(renewed_token);
          setTicket(response.data.data.ticket);
          setRegisteredSupportFiles(response.data.data.support_files);
          setRegisteredSupportMessages(response.data.data.support_messages);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getTicketData();
  }, [id]);

  const handleSelectedFiles = useCallback((files) => {
    setSelectedFiles(files);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("image", file);
      });
      if (supportMessage.trim()) {
        formData.append("support_message", supportMessage);
      } else {
        setSupportMessage("");
      }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ": " + pair[1]);
      // }

      const response = await api.put(
        `/v1/dashboard/tickets/?id=${ticket.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);

      setIsLoading(false);
      if (response.data && response.data.code !== 200) {
        if (response.data.status === "Unauthorized") {
          setUnAuthorizedMsg(
            `${response.data.message} - You have to login again.`
          );
        }
      } else {
        const renewed_token = response.data.access_token;
        updateToken(renewed_token);
        setSelectedFiles([]);
        setRegisteredSupportFiles([
          ...registeredSupportFiles,
          ...response.data.support_files,
        ]);
        if (response.data.support_message) {
          setRegisteredSupportMessages([
            ...registeredSupportMessages,
            response.data.support_message,
          ]);
        }
        setShowSupportTextarea(false);
        setSupportMessage("");
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [selectedFiles, supportMessage, token]);

  const handleSupportMessageChange = useCallback((event) => {
    setSupportMessage(event.target.value);
  }, []);

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {errors.length > 0 && (
        <div className="message-errors-container alert alert-danger">
          {errors.map((err, index) => (
            <span key={index}>{err}</span>
          ))}
        </div>
      )}
      {!isLoading && (
        // <form onSubmit={handleSubmit}>
        <form>
          {id && (
            <>
              <label for="id">Id:</label>
              <input
                type="text"
                name="id"
                readOnly
                className="form-group form-control"
                value={ticket.id}
              />
            </>
          )}
          <label for="category">Category:</label>
          <input
            type="text"
            name="category"
            readOnly={id ? true : false}
            className="form-group form-control"
            value={ticket.category}
          />
          <label for="title">Title:</label>
          <input
            type="text"
            name="title"
            readOnly={id ? true : false}
            className="form-group form-control"
            value={ticket.title}
          />
          <label for="content">Content:</label>
          <textarea
            name="content"
            readOnly={id ? true : false}
            className="form-group form-control"
            style={{ minHeight: 200, resize: "none" }}
            value={ticket.content}
          />
          <FilesSelector
            currentFiles={selectedFiles}
            handleSelectedFiles={handleSelectedFiles}
            registeredSupportFiles={registeredSupportFiles}
          />
          <div className="support-messages">
            <h4>Support Messages:</h4>
            {registeredSupportMessages.map((sMessage) => (
              <blockquote key={sMessage.id}>
                <p>{sMessage.message}</p>
                <footer>{sMessage.updated}</footer>
              </blockquote>
            ))}
          </div>
          {showSupportTextarea && (
            <textarea
              name="support-message"
              className="form-group form-control"
              style={{ minHeight: 150, resize: "none" }}
              onChange={handleSupportMessageChange}
              value={supportMessage}
            />
          )}
          <button
            id="add-support-message-btn"
            type="button"
            className="btn btn-link"
            onClick={() => {
              setSupportMessage("");
              setShowSupportTextarea(!showSupportTextarea);
            }}
          >
            {showSupportTextarea
              ? "Close Support Message (-)"
              : "Add a new Support Message (+)"}
          </button>
          <div className="action-buttons">
            <button
              type="button"
              className="btn btn-default"
              onClick={handleSubmit}
              disabled={
                supportMessage || selectedFiles.length > 0 ? false : true
              }
            >
              Update Ticket
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ViewEditTicketForm;
