function InrTransactionsPainel({ companyData }) {
  return (
    <div className="panel panel-default">
      <div className="panel-heading" id="panel-heading-transactions">
        <h4>INR Transactions</h4>
        <span className="panel-info">(* Last 30 Days)</span>
      </div>
      <div className="panel-body">
        {/* Deposits */}
        <h4>Deposits</h4>
        <div className="row">
          <div className="col-sm-6">
            <span>
              Successful Deposits
              <br />
              <strong className="company-data-value">
                {companyData.successful_inr_deposits_count}
              </strong>
            </span>
          </div>
          <div className="col-sm-6">
            <span>
              Successful Deposits Volume
              <br />
              <strong className="company-data-value">
                +{" "}
                {companyData.successful_inr_deposits_amount_sum?.toLocaleString(
                  "en-IN",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                )}
              </strong>
            </span>
          </div>
        </div>

        {/* Withdrawals */}
        <div className="transactions-row-container">
          <h4>Withdrawals</h4>
          <div className="row">
            <div className="col-sm-6">
              <span>
                Successful Withdrawals
                <br />
                <strong className="company-data-value">
                  {companyData.successful_inr_withdrawals_count}
                </strong>
              </span>
            </div>
            <div className="col-sm-6">
              <span>
                Successful Withdrawals Volume
                <br />
                <strong className="company-data-value">
                  -{" "}
                  {companyData.successful_inr_withdrawals_amount_sum?.toLocaleString(
                    "en-IN",
                    {
                      style: "currency",
                      currency: "INR",
                    }
                  )}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InrTransactionsPainel;
