import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
//
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import LoadingIndicator from "../../../components/LoadingIndicator";
import logoBrainPay from "../../../assets/images/logo_brainpay.png";
import logoLyPago from "../../../assets/images/logo_lypago.png";
import "../styles.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("The Email field is required.")
    .email("Type a valid email address."),
  password: Yup.string()
    .required("The Password field is required.")
    .min(8, "The Password field must have 8 digits at least."),
});

function SignUp() {
  // const [fieldsValues, setFieldsValues] = useState({
  //   email: "",
  //   password: "",
  // });
  const [fieldsValues, setFieldsValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const { setCodeExpirationDateTime } = useAuth();

  const handleFieldChange = (event) => {
    const auxFieldsValues = { ...fieldsValues };
    auxFieldsValues[event.target.name] = event.target.value;
    setFieldsValues(auxFieldsValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setErrors([]);
      setIsLoading(true);
      await validationSchema.validate(fieldsValues, {
        abortEarly: false,
      });

      const response = await api.post("/v1/auth/company/account", fieldsValues);
      setIsLoading(false);
      // console.log(response);
      if (
        response.data &&
        response.data.status === "Fail" &&
        !"active" in response.data
      ) {
        setErrors([response.data.message]);
      } else {
        if (response.data.active) {
          alert("You already have an active account!");
          history.push({
            pathname: "/",
          });
        } else {
          const minutesToAdd = 15;
          const currentDate = new Date();
          const codeExpirationDateTime = new Date(
            currentDate.getTime() + minutesToAdd * 60000
          ).getTime();
          setCodeExpirationDateTime(codeExpirationDateTime);
          history.push({
            pathname: "/confirmation-code",
            state: fieldsValues["email"],
          });
        }
      }
    } catch (error) {
      // console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        let validationErrors = [];
        Object.keys(errors).forEach((key) => {
          validationErrors.push(errors[key]);
        });
        setErrors(validationErrors);
        setIsLoading(false);
        return;
      }
    }
  };

  return (
    <div className="auth-form-container">
      {/* <h2>Sign Up - {process.env.REACT_APP_GATEWAY_NAME}</h2> */}
      <img
        src={
          process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
            ? logoBrainPay
            : logoLyPago
        }
        alt={process.env.REACT_APP_GATEWAY_NAME}
        className="logo-img"
      />
      {errors.length > 0 && (
        <div className="message-errors-container alert alert-danger">
          {errors.map((err, index) => (
            <span key={index}>{err}</span>
          ))}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email*"
          onChange={handleFieldChange}
          className="form-group form-control"
          // value={fieldsValues["email"]}
        />
        <input
          type="password"
          name="password"
          placeholder="Password*"
          onChange={handleFieldChange}
          className="form-group form-control"
          // value={fieldsValues["password"]}
        />
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <button type="submit" className="btn btn-default btn-block">
              Sign Up
            </button>
            <Link to="/">Access my account</Link>
          </>
        )}
      </form>
    </div>
  );
}

export default SignUp;
