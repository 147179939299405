// import { useEffect } from "react";
import { BrowserRouter, Link, Switch, Redirect } from "react-router-dom";
//
import Route from "../../../routes/Route";
import Overview from "../Overview";
import Deposits from "../Deposits";
import Withdrawals from "../Withdrawals";
import Profile from "../Profile";
import SupportTickets from "../SupportTickets";
import ViewEditTicketForm from "../SupportTickets/components/ViewEditTicketForm";
import CreateTicketForm from "../SupportTickets/components/CreateTicketForm";
import { useAuth } from "../../../hooks/auth";
import logoBrainPay from "../../../assets/images/logo_brainpay_2.png";
import logoLyPago from "../../../assets/images/logo_lypago.png";
import "../styles.css";

function Dashboard() {
  const { signOut, email, authorized } = useAuth();

  // useEffect(() => {
  //   const { isValidToken, invalidMsg } = authorized;
  //   if (!isValidToken && invalidMsg) {
  //     alert(invalidMsg);
  //   }
  // }, [authorized]);

  return (
    <BrowserRouter>
      {/* HEADER */}
      <header className="dashboard-container" id="dashboard-header">
        <Link to="/dashboard/home">
          <img
            src={
              process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
                ? logoBrainPay
                : logoLyPago
            }
            alt={process.env.REACT_APP_GATEWAY_NAME}
            className="logo-img"
          />
        </Link>
        {/* <h2>{process.env.REACT_APP_GATEWAY_NAME}</h2> */}
        <div id="dashboard-header-right-container">
          <ul className="nav navbar-nav navbar-nav-items-container">
            <li>
              <a
                href={
                  process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
                    ? "https://doc.brainpay.com.br/"
                    : "https://doc.lypago.com.br/"
                }
                target="_blank"
              >
                Documentation
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
                    ? "http://swagger.brainpay.com.br/"
                    : "http://swagger.lypago.com.br/"
                }
                target="_blank"
              >
                Swagger
              </a>
            </li>
          </ul>
          <span>
            <strong>{email}</strong>
          </span>
          <button
            onClick={() => {
              signOut();
            }}
            className="btn btn-link"
          >
            Logout
          </button>
        </div>
      </header>

      {/* SIDE-NAV and CONTENT */}
      <div className="dashboard-container" id="side-nav-content-container">
        <Redirect to="/dashboard/home" />
        <div>
          <nav className="navbar navbar-default" id="dashboard-side-nav">
            <ul className="nav flex-column">
              <li>
                <Link to="/dashboard/home">Overview</Link>
              </li>
              <li>
                <Link to="/dashboard/deposits">Deposits</Link>
              </li>
              <li>
                <Link to="/dashboard/withdrawals">Withdrawals</Link>
              </li>
              <li>
                <Link to="/dashboard/support-tickets">Support Tickets</Link>
              </li>
              <li>
                <Link to="/dashboard/profile">Profile</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="dashboard-content-container">
          <Switch>
            <Route path="/dashboard/home" component={Overview} isPrivate />
            <Route path="/dashboard/deposits" component={Deposits} isPrivate />
            <Route
              path="/dashboard/withdrawals"
              component={Withdrawals}
              isPrivate
            />
            <Route
              path="/dashboard/support-tickets"
              exact
              component={SupportTickets}
              isPrivate
            />
            <Route
              path="/dashboard/support-tickets/create"
              exact
              component={CreateTicketForm}
              isPrivate
            />
            <Route
              path="/dashboard/support-tickets/:id"
              exact
              component={ViewEditTicketForm}
              isPrivate
            />
            <Route
              path="/dashboard/profile"
              exact
              component={Profile}
              isPrivate
            />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Dashboard;
