import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
//
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import LoadingIndicator from "../../../components/LoadingIndicator";
import logoBrainPay from "../../../assets/images/logo_brainpay.png";
import logoLyPago from "../../../assets/images/logo_lypago.png";

const validationSchema = Yup.object().shape({
  confirmation_code: Yup.string()
    .required("The Verification Code is required.")
    .length(6, "The Verification Code must have 6 digits."),
});

function ConfirmationCode() {
  const [fieldsValues, setFieldsValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();
  const { codeExpirationDateTime, setCodeExpirationDateTime } = useAuth();

  useEffect(() => {
    const auxFieldsValues = { ...fieldsValues };
    auxFieldsValues["email"] = location.state;
    setFieldsValues(auxFieldsValues);
  }, [location]);

  const handleFieldChange = (event) => {
    const auxFieldsValues = { ...fieldsValues };
    auxFieldsValues[event.target.name] = event.target.value;
    setFieldsValues(auxFieldsValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setErrors([]);
      setIsLoading(true);

      await validationSchema.validate(fieldsValues, {
        abortEarly: false,
      });
      const currentDateTime = new Date().getTime();
      if (currentDateTime > codeExpirationDateTime) {
        setErrors(["Confirmation code expired"]);
        throw new Error("Confirmation code expired");
      }

      const response = await api.get(
        `/v1/auth/company/account/code/?email=${fieldsValues["email"]}&code=${fieldsValues["confirmation_code"]}`
      );
      setIsLoading(false);
      if (
        response.data &&
        (response.data.status === "Fail" ||
          response.data.status === "Error" ||
          response.data.code !== 200)
      ) {
        setErrors([response.data.message]);
      } else {
        alert(response.data.message);
        history.push({
          pathname: "/",
          // state: fieldsValues["email"],
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        let validationErrors = [];
        Object.keys(errors).forEach((key) => {
          validationErrors.push(errors[key]);
        });
        setErrors(validationErrors);
        return;
      }
    }
  };

  const sendCodeAgain = async () => {
    // event.preventDefault();
    try {
      setErrors([]);
      setIsLoading(true);

      const response = await api.post("/v1/auth/company/account/code", {
        email: fieldsValues["email"],
      });
      // console.log(response);

      setIsLoading(false);
      if (response.data && response.data.code === 200) {
        const minutesToAdd = 15;
        const currentDate = new Date();
        const codeExpirationDateTime = new Date(
          currentDate.getTime() + minutesToAdd * 60000
        ).getTime();
        setCodeExpirationDateTime(codeExpirationDateTime);
        alert("Code resent");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="auth-form-container">
      {/* <h2>Account Verification</h2> */}
      <img
        src={
          process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
            ? logoBrainPay
            : logoLyPago
        }
        alt={process.env.REACT_APP_GATEWAY_NAME}
        className="logo-img"
      />
      <div className="alert alert-info">
        <span>
          We send a code to your email <strong>{fieldsValues["email"]}</strong>.
          Please type it.
        </span>
      </div>
      {errors.length > 0 && (
        <div className="alert alert-danger">
          {errors.map((err, index) => (
            <span key={index}>{err}</span>
          ))}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="confirmation_code"
          placeholder="Verification Code*"
          onChange={handleFieldChange}
          className="form-group form-control"
        />
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <button type="submit" className="btn btn-default btn-block">
              Confirm
            </button>
            <a className="btn btn-link btn-block" onClick={sendCodeAgain}>
              Send me again
            </a>
          </>
        )}
      </form>
    </div>
  );
}

export default ConfirmationCode;
