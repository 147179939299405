import { Switch } from "react-router-dom";

// auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ConfirmationCode from "../pages/auth/ConfirmationCode";
import RecoverPassword from "../pages/auth/RecoverPassword";
import ResetPassword from "../pages/auth/ResetPassword";
// private
import Dashboard from "../pages/private/Dashboard";
import Route from "./Route";

const Routes = () => (
  <Switch>
    {/* auth */}
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/confirmation-code" component={ConfirmationCode} />
    <Route path="/recover-password" component={RecoverPassword} />
    <Route path="/reset-password/:jwt" component={ResetPassword} />
    {/* private */}
    <Route path="/dashboard" component={Dashboard} isPrivate />
  </Switch>
);

export default Routes;
