import { useCallback, useState, useEffect } from "react";
import * as Yup from "yup";
//
import LoadingIndicator from "../../../../src/components/LoadingIndicator";
import api from "../../../../src/services/api";
import { useAuth } from "../../../../src/hooks/auth";
import getValidationErrors from "../../../utils/getValidationErrors";
// import "./styles.css";

const MIN_CODE_DIGITS = 3;
const MIN_KEY_DIGITS = 15;
const validationSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .required("The Code field is required.")
    .min(
      MIN_CODE_DIGITS,
      `The Code field must have a mininum of ${MIN_CODE_DIGITS} digits.`
    )
    .max(10, `The Code field must have a maximum of 10 digits.`)
    .matches(
      /^[A-Z0-9\s]+$/,
      "The Code field allows only alphabets and numbers."
    )
    .nullable(),
  name: Yup.string()
    .max(100, `The Name field must have a maximum of 100 digits.`)
    .nullable(),
  key: Yup.string()
    .transform((value) => (!value ? null : value))
    .min(
      MIN_KEY_DIGITS,
      `The Key field must have a mininum of ${MIN_KEY_DIGITS} digits.`
    )
    .max(50, `The Key field must have a maximum of 50 digits.`)
    .matches(
      /^[aA-zZ0-9\s]+$/,
      "The Key field allows only alphabets and numbers."
    )
    .nullable(),
  deposit_webhook_url: Yup.string()
    .url("Deposit webhook url require a valid url address")
    .nullable(),
  withdrawal_webhook_url: Yup.string()
    .url("Withdrawal webhook url require a valid url address")
    .nullable(),
  // min_recharge: Yup.number()
  //   .transform((value) => (!value ? null : value))
  //   .typeError("Min recharge must be a number (min 0)")
  //   .min(0, "Min recharge must be a positive number")
  //   .nullable(),
  // min_withdrawal: Yup.number()
  //   .transform((value) => (!value ? null : value))
  //   .typeError("Min withdrawal must be a number (min 0)")
  //   .min(0, "Min withdrawal must be a positive number")
  //   .nullable(),
});

function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  const [editModeEnable, setEditModeEnable] = useState(false);
  const [fieldsValues, setFieldsValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        setIsLoading(true);
        const url = `/v1/dashboard/profile`;
        console.log(url);
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response);
        setIsLoading(false);
        if (response.data && response.data.code !== 200) {
          if (response.data.status === "Unauthorized") {
            setUnAuthorizedMsg(
              `${response.data.message} - You have to login again.`
            );
          }
        } else {
          const renewed_token = response.data.access_token;
          updateToken(renewed_token);
          setCompanyData(response.data.data);
          setFieldsValues({ ...response.data.data });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getCompanyData();
  }, []);

  const handleFieldChange = useCallback(
    (event) => {
      let auxFieldsValues = { ...fieldsValues };
      let value = event.target.value;
      if (event.target.name == "code") {
        // code field doesn't allow accents and white spaces
        value = event.target.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .trim();
      }
      auxFieldsValues[event.target.name] = value;
      setFieldsValues(auxFieldsValues);
    },
    [fieldsValues]
  );

  const handleCancelButton = useCallback(() => {
    setFieldsValues(companyData);
    setEditModeEnable(false);
  }, [companyData]);

  const handleSubmitButton = async (event) => {
    event.preventDefault();
    try {
      setErrors([]);
      setIsLoading(true);

      // Object.keys(fieldsValues).forEach((key) => {
      //   if (fieldsValues[key] == "") {
      //     fieldsValues[key] = null;
      //   }
      //   console.log(`${key}: ${fieldsValues[key]}`);
      // });

      await validationSchema.validate(fieldsValues, {
        abortEarly: false,
      });

      const url = `/v1/dashboard/profile`;
      console.log(url);
      const response = await api.put(url, fieldsValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setIsLoading(false);
      if (response.data && response.data.code !== 200) {
        if (response.data.status === "Unauthorized") {
          setUnAuthorizedMsg(
            `${response.data.message} - You have to login again.`
          );
        }
      } else {
        const renewed_token = response.data.access_token;
        updateToken(renewed_token);
        setCompanyData(response.data.data);
        setFieldsValues({ ...response.data.data });
        setEditModeEnable(false);
      }
    } catch (error) {
      // console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        let validationErrors = [];
        Object.keys(errors).forEach((key) => {
          validationErrors.push(errors[key]);
        });
        setErrors(validationErrors);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    }
  };

  const { code } = companyData;

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {errors.length > 0 && (
        <div className="message-errors-container alert alert-danger">
          {errors.map((err, index) => (
            <span key={index}>{err}</span>
          ))}
        </div>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmitButton}>
          <label for="code">Code*:</label>
          <span className="field-notice">
            {" (this field can only be changed once)"}
          </span>
          <input
            type="text"
            name="code"
            placeholder="Shortname of your company. Please use only one word."
            onChange={handleFieldChange}
            readOnly={code || !editModeEnable ? true : false}
            className="form-group form-control"
            value={code ? code : fieldsValues["code"]}
          />
          <label for="code">Name:</label>
          <input
            type="text"
            name="name"
            placeholder="Complete name of your company."
            onChange={handleFieldChange}
            readOnly={!editModeEnable}
            className="form-group form-control"
            value={fieldsValues["name"]}
          />
          <label for="key">Key:</label>
          <input
            type="text"
            name="key"
            onChange={handleFieldChange}
            readOnly={!editModeEnable}
            className="form-group form-control"
            value={fieldsValues["key"]}
          />
          <label for="deposit_webhook_url">Deposit webhook url:</label>
          <input
            type="text"
            name="deposit_webhook_url"
            onChange={handleFieldChange}
            readOnly={!editModeEnable}
            className="form-group form-control"
            value={fieldsValues["deposit_webhook_url"]}
          />
          <label for="withdrawal_webhook_url">Withdrawal webhook url:</label>
          <input
            type="text"
            name="withdrawal_webhook_url"
            onChange={handleFieldChange}
            readOnly={!editModeEnable}
            className="form-group form-control"
            value={fieldsValues["withdrawal_webhook_url"]}
          />
          <label for="min_recharge">Min recharge:</label>
          <input
            type="number"
            name="min_recharge"
            onChange={handleFieldChange}
            readOnly
            className="form-group form-control"
            value={fieldsValues["min_recharge"]}
          />
          <label for="min_withdrawal">Min withdrawal:</label>
          <input
            type="number"
            name="min_withdrawal"
            onChange={handleFieldChange}
            readOnly
            className="form-group form-control"
            value={fieldsValues["min_withdrawal"]}
          />
          <div className="action-buttons">
            {!editModeEnable && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => setEditModeEnable(true)}
              >
                Edit
              </button>
            )}
            {editModeEnable && (
              <button
                type="button"
                className="btn btn-default"
                onClick={handleCancelButton}
              >
                Cancel
              </button>
            )}
            {editModeEnable && (
              <button type="submit" className="btn btn-default">
                Submit
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
}

export default Profile;
