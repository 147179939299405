import React, { createContext, useCallback, useState, useContext } from "react";
//
// import api from "../services/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const email = localStorage.getItem("@iwallet:email");
    const token = localStorage.getItem("@iwallet:token");
    const codeExpirationDateTime = localStorage.getItem(
      "@iwallet:codeExpirationDateTime"
    );

    if (email && token) {
      return { email, token };
    }

    if (codeExpirationDateTime) {
      return { codeExpirationDateTime };
    }

    return {};
  });

  const [authorized, setAuthorized] = useState({
    isValidToken: false,
    invalidMsg: "",
  });

  const setCodeExpirationDateTime = useCallback(
    async (codeExpirationDateTime) => {
      localStorage.setItem(
        "@iwallet:codeExpirationDateTime",
        codeExpirationDateTime
      );
      setData({ codeExpirationDateTime });
    },
    []
  );

  const signIn = useCallback(async (email, token) => {
    localStorage.setItem("@iwallet:email", email);
    localStorage.setItem("@iwallet:token", token);
    localStorage.removeItem("@iwallet:codeExpirationDateTime");

    setData({ email, token });
    setAuthorized({ isValidToken: true, invalidMsg: "" });
  }, []);

  const updateToken = useCallback(
    async (token) => {
      localStorage.setItem("@iwallet:token", token);
      setAuthorized({ ...authorized, isValidToken: true });
    },
    [authorized]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("@iwallet:email");
    localStorage.removeItem("@iwallet:token");
    setData({});
    setAuthorized({ ...authorized, isValidToken: false });
  }, [authorized]);

  const setUnAuthorizedMsg = useCallback(
    (msg) => {
      setAuthorized({ ...authorized, invalidMsg: msg });
      setTimeout(() => signOut(), 1000);
    },
    [authorized, signOut]
  );

  return (
    <AuthContext.Provider
      value={{
        email: data.email,
        token: data.token,
        codeExpirationDateTime: data.codeExpirationDateTime,
        authorized,
        setCodeExpirationDateTime,
        signIn,
        signOut,
        updateToken,
        setUnAuthorizedMsg,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}
