//
import "./styles.css";
import DepositsPainel from "./components/DepositsPainel";
import InrDepositsPainel from "./components/InrDepositsPainel";

function Deposits() {
  return (
    <div>
      <DepositsPainel />
      {process.env.REACT_APP_GATEWAY_NAME === "BrainPay" && (
        <InrDepositsPainel />
      )}
    </div>
  );
}

export default Deposits;
