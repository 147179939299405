function GeneralPainel({ companyData }) {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h4>General</h4>
      </div>
      <div className="panel-body">
        {!companyData.code && (
          <div class="alert alert-warning">
            <strong>Warning!</strong> Access the Profile tab and choose a 'Code'
            in order to start making transactions.
          </div>
        )}
        <div className="row">
          <div className="col-sm-3">
            <span>
              Code
              <br />
              <strong className="company-data-value">{companyData.code}</strong>
            </span>
          </div>
          <div className="col-sm-3">
            <span>
              Status
              <br />
              <strong
                className={`company-data-value ${
                  companyData.active === true ? "green-value" : "red-value"
                }`}
              >
                {companyData.active === true ? "Active" : "Inactive"}
              </strong>
            </span>
          </div>
          <div className="col-sm-3">
            <span>
              Balance
              <br />
              <strong
                className={`company-data-value ${
                  companyData.balance > 0 ? "green-value" : "red-value"
                }`}
              >
                {companyData.balance?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </span>
          </div>
          <div className="col-sm-3">
            <span>
              Frozen
              <br />
              <strong className="company-data-value">
                {companyData.frozen?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralPainel;
