import { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
//
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../services/api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import logoBrainPay from "../../../assets/images/logo_brainpay.png";
import logoLyPago from "../../../assets/images/logo_lypago.png";
// import "./styles.css";

const validationSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("The Password field is required.")
    .min(8, "The Password field must have 8 digits at least."),
});

function ResetPassword() {
  // const [fieldsValues, setFieldsValues] = useState({
  //   email: "",
  //   password: "",
  // });
  const [fieldsValues, setFieldsValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [successMsgs, setSuccessMsgs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // let history = useHistory();
  // const { signIn } = useAuth();
  let { jwt } = useParams();
  const [isAValidJWT, setIsAValidJWT] = useState(false);

  const checkIsAValidJWT = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `/v1/auth/company/account/recover/?jwt=${jwt}`
      );
      // console.log(response);
      setIsLoading(false);
      if (response.data && response.data.status === "Fail") {
        setErrors([response.data.message]);
      } else {
        setIsAValidJWT(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [jwt]);

  useEffect(() => {
    checkIsAValidJWT();
  }, [jwt, checkIsAValidJWT]);

  const handleFieldChange = (event) => {
    const auxFieldsValues = { ...fieldsValues };
    auxFieldsValues[event.target.name] = event.target.value;
    setFieldsValues(auxFieldsValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setErrors([]);
      setSuccessMsgs([]);
      setIsLoading(true);
      await validationSchema.validate(fieldsValues, {
        abortEarly: false,
      });
      // console.log(fieldsValues);
      const response = await api.patch(
        `/v1/auth/company/account/recover/${jwt}`,
        fieldsValues
      );
      setIsLoading(false);
      console.log(response);
      if (response.data && response.data.code !== 200) {
        setErrors([response.data.message]);
      } else {
        setSuccessMsgs(["SUCCESS: password updated. Access your account"]);
      }
    } catch (error) {
      // console.log(error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        let validationErrors = [];
        Object.keys(errors).forEach((key) => {
          validationErrors.push(errors[key]);
        });
        setErrors(validationErrors);
        setIsLoading(false);
        return;
      }
    }
  };

  if (isAValidJWT) {
    return (
      <div className="auth-form-container">
        {/* <h2>{process.env.REACT_APP_GATEWAY_NAME} - Reset Password</h2> */}
        <img
          src={
            process.env.REACT_APP_GATEWAY_NAME === "BrainPay"
              ? logoBrainPay
              : logoLyPago
          }
          alt={process.env.REACT_APP_GATEWAY_NAME}
          className="logo-img"
        />
        {errors.length > 0 && (
          <div className="message-errors-container alert alert-danger">
            {errors.map((err, index) => (
              <span key={index}>{err}</span>
            ))}
          </div>
        )}
        {successMsgs.length > 0 && (
          <div className="alert alert-success">
            {successMsgs.map((msg, index) => (
              <span key={index}>{msg}</span>
            ))}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            name="new_password"
            placeholder="Type a New Password*"
            onChange={handleFieldChange}
            className="form-group form-control"
            // value={fieldsValues["password"]}
          />
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <>
              <button type="submit" className="btn btn-default btn-block">
                Reset password
              </button>
              <Link to="/">Access my account</Link>
            </>
          )}
        </form>
      </div>
    );
  } else {
    return (
      <div className="auth-form-container">
        {isLoading && (
          <span className="loading-span text-info">Loading...</span>
        )}
        {!isLoading && (
          <>
            <h2>{process.env.REACT_APP_GATEWAY_NAME} - Forbidden</h2>
            <div className="message-errors-container alert alert-danger">
              {errors.map((err, index) => (
                <span key={index}>{err}</span>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ResetPassword;
