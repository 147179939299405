import { ValidationError } from "yup";

export default function getValidationErrors(err) {
  const validationErrors = {};

  if (err instanceof ValidationError) {
    err.inner.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
  }

  return validationErrors;
}
