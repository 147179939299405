import { useCallback, useState, useEffect } from "react";
import Pagination from "react-js-pagination";
//
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";
import FilterForm from "../../../components/FilterForm";

const defaultActivePage = 1;
const itemsCountPerPage = 10;

const INR_WITHDRAWAL_STATUS = {
  PROCESSING: {
    value: 0,
    text: "Processing",
  },
  APPROVED: {
    value: 2,
    text: "Approved",
  },
  REJECTED: {
    value: 3,
    text: "Rejected",
  },
};

function InrWithdrawalsPainel() {
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);
  const [activePage, setActivePage] = useState(defaultActivePage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  let [filtersQueryObj, setFiltersQueryObj] = useState({});
  // const { token, signOut, updateToken } = useAuth();
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();

  useEffect(() => {
    const getWithdrawalsData = async () => {
      try {
        setIsLoading(true);
        const offSet = (activePage - 1) * itemsCountPerPage;
        let filtersQueryStr = "";
        Object.keys(filtersQueryObj).forEach((key) => {
          if (filtersQueryObj[key]) {
            filtersQueryStr += `&${key}=${filtersQueryObj[key]}`;
          }
        });
        const url = `/v1/dashboard/withdrawals/inr/?offset=${offSet}&limit=${itemsCountPerPage}${filtersQueryStr}`;
        console.log(url);
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setIsLoading(false);
        if (response.data && response.data.code !== 200) {
          if (response.data.status === "Unauthorized") {
            setUnAuthorizedMsg(
              `${response.data.message} - You have to login again.`
            );
          }
        } else {
          const renewed_token = response.data.access_token;
          updateToken(renewed_token);
          setWithdrawals(response.data.data.withdrawals);
          setTotalItemsCount(response.data.data.total);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getWithdrawalsData();
  }, [activePage, filtersQueryObj]);

  const handlePageChange = useCallback((pageNumber) => {
    setActivePage(pageNumber);
  }, []);

  const handleFilterChange = useCallback(
    (queryObj) => {
      setFiltersQueryObj({ ...filtersQueryObj, ...queryObj });
      setActivePage(defaultActivePage);
    },
    [filtersQueryObj]
  );

  return (
    <div>
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>INR Withdrawals</h4>
        </div>
        <div className="panel-body">
          <div className="table-responsive">
            <FilterForm
              onFilterChange={handleFilterChange}
              statusValues={INR_WITHDRAWAL_STATUS}
            />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <>
                <header className="header-table">
                  <span>Total: {totalItemsCount}</span>
                </header>
                {withdrawals.length > 0 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>transaction id</th>
                        {/* <th>user</th> */}
                        <th>amount</th>
                        <th>currency</th>
                        <th>registered on</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawals.map((d, index) => {
                        let statusClass = "orange-value";
                        if (d.status?.includes("Approved"))
                          statusClass = "green-value";
                        else if (d.status?.includes("Rejected"))
                          statusClass = "red-value";
                        return (
                          <tr key={index}>
                            <td>{d.transaction_id}</td>
                            {/* <td>{d.user}</td> */}
                            <td>
                              {d.amount?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
                            </td>
                            <td>{d.currency}</td>
                            <td>{d.registered}</td>
                            <td className={statusClass}>{d.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
        <div className="pagination-container">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default InrWithdrawalsPainel;
