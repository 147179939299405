import TicketsPainel from "./components/TicketsPainel";

function SupportTickets() {
  return (
    <div>
      <TicketsPainel />
    </div>
  );
}

export default SupportTickets;
