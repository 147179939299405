//
import "./styles.css";
import WithdrawalsPainel from "./components/WithdrawalsPainel";
import InrWithdrawalsPainel from "./components/InrWithdrawalsPainel";

function Withdrawals() {
  return (
    <div>
      <WithdrawalsPainel />
      {process.env.REACT_APP_GATEWAY_NAME === "BrainPay" && (
        <InrWithdrawalsPainel />
      )}
    </div>
  );
}

export default Withdrawals;
