import { useState, useEffect, useCallback } from "react";
//
import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import "./styles.css";
import GeneralPainel from "./components/GeneralPainel";
import TransactionsPainel from "./components/TransactionsPainel";
import InrTransactionsPainel from "./components/InrTransactionsPainel";
import LoadingIndicator from "../../../components/LoadingIndicator";

function Overview() {
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});
  // const { token, signOut, updateToken } = useAuth();
  const { token, updateToken, setUnAuthorizedMsg } = useAuth();

  const getDashboardHomeData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get("/v1/dashboard/home", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setIsLoading(false);
      if (response.data && response.data.code !== 200) {
        if (response.data.status === "Unauthorized") {
          setUnAuthorizedMsg(
            `${response.data.message} - You have to login again.`
          );
        }
      } else {
        // alert("Success");
        const renewed_token = response.data.access_token;
        updateToken(renewed_token);
        setCompanyData(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getDashboardHomeData();
  }, []);

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <GeneralPainel companyData={companyData} />
          <TransactionsPainel companyData={companyData} />
          {process.env.REACT_APP_GATEWAY_NAME === "BrainPay" && (
            <InrTransactionsPainel companyData={companyData} />
          )}
        </>
      )}
    </div>
  );
}

export default Overview;
